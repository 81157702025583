import { useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";

export const useExchangeRatesTheme = () => {
  const { theme } = useTheme();

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "light") {
      root.style.setProperty("--theme-bg", "url(/assets/light.svg)");
      root.style.setProperty("--bg-color", "rgba(245, 244, 238, 0.8)");
      root.style.setProperty("--action-button-color", "#1E293B");
      root.style.setProperty("--action-button-bg", "#FFFFFF");
      root.style.setProperty("--action-button-active-bg", "#D4A100");
      root.style.setProperty("--action-button-active-color", "#FFFFFF");
      root.style.setProperty("--action-button-hover-bg", "#D4A100");
      root.style.setProperty("--action-button-hover-color", "#FFFFFF");
      root.style.setProperty("--preset-button-border", "rgba(51, 65, 85, 0.1)");
      root.style.setProperty("--preset-button-color", "#1E293B");
      root.style.setProperty("--input-container-border", "none");
      root.style.setProperty("--input-container-bg", "#FFFFFF");
      root.style.setProperty("--exchange-rates-updated_time-border", "none");
      root.style.setProperty("--exchange-rates-updated_time-bg", "#FFFFFF");
      root.style.setProperty("--crypto-list-container-bg", "#FFFFFF");
      root.style.setProperty("--crypto-header-color", "rgba(71, 85, 105, 0.7)");
      root.style.setProperty("--exchange-name-color", "#1E293B");
      root.style.setProperty("--crypto-rank-color", "#1E293B");
      root.style.setProperty(
        "--crypto-item-hover-box-shadow",
        "rgba(0, 0, 0, 0.2)"
      );
      root.style.setProperty("--rate-info-updated-color", "#f0b90b");
      root.style.setProperty("--price-updated-color", "#f0b90b");
      root.style.setProperty("--buy-button-bg", "#1E293B");
      root.style.setProperty("--buy-button-color", "#FFFFFF");
      root.style.setProperty("--crypto-item-highlighted-bg", "#F9F7F3");
      root.style.setProperty(
        "--price-info-highlighted-color",
        "rgba(231, 181, 98, 1)"
      );
      root.style.setProperty("--update-info-color", "#1E293B");
      root.style.setProperty("--updated-time-countdown-color", "#D4A100");
      root.style.setProperty(
        "--last-update-time-color",
        "rgba(100, 116, 139, 0.8)"
      );
      root.style.setProperty("--fee-explanation-color", "#D4A100");
      root.style.setProperty(
        "--info-icon-wrapper-color",
        "rgba(125, 162, 212, 0.7)"
      );
      root.style.setProperty("--info-icon-color", "#888");
      root.style.setProperty("--tooltip-bg", "#333");
      root.style.setProperty("--tooltip-color", "#fff");
      root.style.setProperty("--input-mode-container-color", "#1E293B");
      root.style.setProperty("--input-mode-text", "#D4A100");
      root.style.setProperty(
        "--toggle-buttons-border",
        "rgba(217, 217, 217, 0.3)"
      );
      root.style.setProperty("--toggle-buttons-bg", "rgba(217, 217, 217, 0.3)");
      root.style.setProperty("--toggle-button-color", "rgba(30, 41, 59, 0.64)");
      root.style.setProperty(
        "--toggle-button-active-bg",
        "rgba(212, 161, 0, 1)"
      );
      root.style.setProperty("--toggle-button-active-color", "#FFFFFF");
      root.style.setProperty(
        "--input-wrapper-border",
        "rgba(125, 162, 212, 0.3)"
      );
      root.style.setProperty("--amount-input-color", "#1E293B");
      root.style.setProperty("--currency-unit-color", "rgba(51, 65, 85, 0.5)");
      root.style.setProperty("--crypto-list-color", "#1E293B");
      root.style.setProperty("--clock-icon-color", "#D4A100");
      root.style.setProperty("--info-icon-color", "rgba(71, 85, 105, 0.7)");
    } else {
      root.style.setProperty("--theme-bg", "url(/assets/dark.svg)");
      root.style.setProperty("--bg-color", "rgba(24, 26, 31, 0.5)");
      root.style.setProperty("--action-button-color", "#FFFFFF");
      root.style.setProperty("--action-button-bg", "rgba(30, 30, 30, 0.2)");
      root.style.setProperty("--action-button-active-bg", "#D6C6AB");
      root.style.setProperty("--action-button-active-color", "#1E293B");
      root.style.setProperty("--action-button-hover-bg", "#D6C6AB");
      root.style.setProperty("--action-button-hover-color", "#1E293B");
      root.style.setProperty(
        "--preset-button-border",
        "rgba(125, 162, 212, 0.3)"
      );
      root.style.setProperty("--preset-button-color", "#FFFFFF");
      root.style.setProperty(
        "--input-container-border",
        "rgba(214, 198, 171, 0.5)"
      );
      root.style.setProperty("--input-container-bg", "rgba(42, 59, 88, 0.5)");
      root.style.setProperty(
        "--exchange-rates-updated_time-border",
        "rgba(214, 198, 171, 0.5)"
      );
      root.style.setProperty(
        "--exchange-rates-updated_time-bg",
        "rgba(24, 26, 31, 0.5)"
      );
      root.style.setProperty(
        "--crypto-list-container-bg",
        "rgba(24, 26, 31, 0.5)"
      );
      root.style.setProperty(
        "--crypto-header-color",
        "rgba(125, 162, 212, 0.7)"
      );
      root.style.setProperty("--exchange-name-color", "#FFFFFF");
      root.style.setProperty("--crypto-rank-color", "#FFFFFF");
      root.style.setProperty(
        "--crypto-item-hover-box-shadow",
        "rgba(0, 0, 0, 0.2)"
      );
      root.style.setProperty("--rate-info-updated-color", "#f0b90b");
      root.style.setProperty("--price-updated-color", "#f0b90b");
      root.style.setProperty("--buy-button-bg", "rgba(214, 198, 171, 1)");
      root.style.setProperty("--buy-button-color", "rgba(30, 41, 59, 1)");
      root.style.setProperty(
        "--crypto-item-highlighted-bg",
        "rgba(160, 185, 210, 0.1)"
      );
      root.style.setProperty(
        "--price-info-highlighted-color",
        "rgba(231, 181, 98, 1)"
      );
      root.style.setProperty("--update-info-color", "#FFFFFF");
      root.style.setProperty(
        "--updated-time-countdown-color",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty(
        "--last-update-time-color",
        "rgba(100, 116, 139, 0.8)"
      );
      root.style.setProperty(
        "--fee-explanation-color",
        "rgba(148, 163, 184, 1)"
      );
      root.style.setProperty(
        "--info-icon-wrapper-color",
        "rgba(125, 162, 212, 0.7)"
      );
      root.style.setProperty("--info-icon-color", "#888");
      root.style.setProperty("--tooltip-bg", "#333");
      root.style.setProperty("--tooltip-color", "#fff");
      root.style.setProperty("--input-mode-container-color", "#FFFFFF");
      root.style.setProperty("--input-mode-text", "#D6C6AB");
      root.style.setProperty(
        "--toggle-buttons-border",
        "rgba(214, 198, 171, 0.5)"
      );
      root.style.setProperty("--toggle-buttons-bg", "rgba(217, 217, 217, 0.3)");
      root.style.setProperty("--toggle-button-color", "#D6C6AB");
      root.style.setProperty("--toggle-button-active-bg", "#D6C6AB");
      root.style.setProperty("--toggle-button-active-color", "#1E293B");
      root.style.setProperty(
        "--input-wrapper-border",
        "rgba(125, 162, 212, 0.3)"
      );
      root.style.setProperty("--amount-input-color", "#FFFFFF");
      root.style.setProperty(
        "--currency-unit-color",
        "rgba(214, 198, 171, 0.8)"
      );
      root.style.setProperty("--crypto-list-color", "#FFFFFF");
      root.style.setProperty("--clock-icon-color", "#D6C6AB");
      root.style.setProperty("--info-icon-color", "rgba(125, 162, 212, 0.7)");
    }
  }, [theme]);
};
