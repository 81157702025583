import React, { useState, useEffect } from "react";
import "./ManagePosts.css";

const ManagePosts = () => {
  const [posts, setPosts] = useState([]);
  const [filter, setFilter] = useState("all");
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

  useEffect(() => {
    fetch(`${BACKEND_HOST}/admin/news`, {
      headers: {
        Authorization: "bestUsdtPrice",
      },
    })
      .then((response) => response.json())
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching posts:", error));
  }, [BACKEND_HOST]);

  const filteredPosts = posts.filter((post) => {
    if (filter === "published") return post.publish === true;
    if (filter === "draft") return post.publish === false;
    return true;
  });

  const handleUnpublish = async (newsId) => {
    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/news/${newsId}/unpublish`,
        {
          method: "POST",
          headers: {
            Authorization: "bestUsdtPrice",
          },
        }
      );

      if (response.ok) {
        // 更新本地狀態
        setPosts(
          posts.map((post) =>
            post.news_id === newsId ? { ...post, publish: false } : post
          )
        );
      } else {
        console.error("下架失敗");
      }
    } catch (error) {
      console.error("下架文章時發生錯誤:", error);
    }
  };

  const handleDelete = async (newsId) => {
    const userInput = prompt("請輸入 'DELETE' 以確認刪除文章");

    if (userInput !== "DELETE") {
      alert("取消刪除");
      return;
    }

    try {
      const response = await fetch(`${BACKEND_HOST}/admin/news/${newsId}`, {
        method: "DELETE",
        headers: {
          Authorization: "bestUsdtPrice",
        },
      });

      if (response.ok) {
        // 更新本地狀態，移除已刪除的文章
        setPosts(posts.filter((post) => post.news_id !== newsId));
        alert("文章已成功刪除");
      } else {
        alert("刪除失敗");
      }
    } catch (error) {
      console.error("刪除文章時發生錯誤:", error);
      alert("刪除文章時發生錯誤");
    }
  };

  return (
    <div className="manage-posts-app-wrapper">
      <div className="manage-posts">
        <div className="manage-posts__container">
          <div className="filter-buttons">
            <button onClick={() => setFilter("all")}>全部</button>
            <button onClick={() => setFilter("published")}>已發佈</button>
            <button onClick={() => setFilter("draft")}>草稿</button>
          </div>
          {filter === "all" ? (
            <>
              <h2 className="manage-posts__title">已發佈</h2>
              <div className="manage-posts__article-grid">
                {posts
                  .filter((post) => post.publish)
                  .map((post) => (
                    <div key={post.id} className="manage-posts__article-card">
                      <div className="manage-posts__article-images">
                        <img
                          src={post.cover}
                          alt={post.title}
                          className="manage-posts__article-main-image"
                        />
                      </div>
                      <div className="manage-posts__article-content">
                        <h3 className="manage-posts__article-title">
                          {post.title}
                        </h3>
                        <p className="manage-posts__article-excerpt">
                          {post.excerpt}
                        </p>
                        <div className="manage-posts__article-buttons">
                          <button
                            className="manage-posts__edit-button"
                            onClick={() =>
                              (window.location.href = `/admin/news/edit/${post.news_id}`)
                            }
                          >
                            編輯文章
                          </button>
                          <button
                            className="manage-posts__edit-button"
                            onClick={() => handleUnpublish(post.news_id)}
                          >
                            下架文章
                          </button>
                          <button
                            className="manage-posts__edit-button"
                            onClick={() => handleDelete(post.news_id)}
                          >
                            刪除文章
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <h2 className="manage-posts__title">草稿</h2>
              <div className="manage-posts__article-grid">
                {posts
                  .filter((post) => !post.publish)
                  .map((post) => (
                    <div key={post.id} className="manage-posts__article-card">
                      <div className="manage-posts__article-images">
                        <img
                          src={post.cover}
                          alt={post.title}
                          className="manage-posts__article-main-image"
                        />
                      </div>
                      <div className="manage-posts__article-content">
                        <h3 className="manage-posts__article-title">
                          {post.title}
                        </h3>
                        <p className="manage-posts__article-excerpt">
                          {post.excerpt}
                        </p>
                        <div className="manage-posts__article-buttons">
                          <button
                            className="manage-posts__edit-button"
                            onClick={() =>
                              (window.location.href = `/admin/news/edit/${post.news_id}`)
                            }
                          >
                            編輯文章
                          </button>
                          <button
                            className="manage-posts__edit-button"
                            onClick={() => handleDelete(post.news_id)}
                          >
                            刪除文章
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <>
              <h2 className="manage-posts__title">
                {filter === "published" ? "已發佈" : "草稿"}
              </h2>
              <div className="manage-posts__article-grid">
                {filteredPosts.map((post) => (
                  <div key={post.id} className="manage-posts__article-card">
                    <div className="manage-posts__article-images">
                      <img
                        src={post.cover}
                        alt={post.title}
                        className="manage-posts__article-main-image"
                      />
                    </div>
                    <div className="manage-posts__article-content">
                      <h3 className="manage-posts__article-title">
                        {post.title}
                      </h3>
                      <p className="manage-posts__article-excerpt">
                        {post.excerpt}
                      </p>
                      <div className="manage-posts__article-buttons">
                        <button
                          className="manage-posts__edit-button"
                          onClick={() =>
                            (window.location.href = `/admin/news/edit/${post.news_id}`)
                          }
                        >
                          編輯文章
                        </button>
                        {post.publish && (
                          <button
                            className="manage-posts__edit-button"
                            onClick={() => handleUnpublish(post.news_id)}
                          >
                            下架文章
                          </button>
                        )}
                        <button
                          className="manage-posts__edit-button"
                          onClick={() => handleDelete(post.news_id)}
                        >
                          刪除文章
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagePosts;
