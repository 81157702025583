import React from "react";
import { NavLink } from "react-router-dom";
import "./AdminDashboardSidebar.css";

const Sidebar = () => {
  return (
    <div className="admin-dashboard-sidebar">
      <h2>管理選單</h2>
      <nav>
        <NavLink to="/admin/manage-news" activeClassName="active">
          管理文章
        </NavLink>
        <NavLink to="/admin/news/edit" activeClassName="active">
          編輯新聞
        </NavLink>
        <NavLink to="/admin/news/tags" activeClassName="active">
          文章標籤
        </NavLink>
        <NavLink to="/admin/news/categories" activeClassName="active">
          文章分類
        </NavLink>
      </nav>
    </div>
  );
};

export default Sidebar;
