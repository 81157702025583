import React, { useState, useEffect, useRef, useCallback } from "react";
import Quill from "quill";
// import { SnowTheme } from 'quill-color-picker-enhance';
import "quill/dist/quill.snow.css";
// import 'quill-color-picker-enhance/dist/index.css';
import "./NewsEditPage.css";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import QuillTableBetter from "quill-table-better";
import "quill-table-better/dist/quill-table-better.css";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
// import { InitTableOfContents } from "./NewsEditToc";

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;

// 註冊增強的 Snow 主題
// Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);
// 註冊 quill-table-better 模組
Quill.register(
  {
    "modules/table-better": QuillTableBetter,
  },
  true
);

// 註冊目錄模組

function NewsEditPage() {
  const [currentNewsId, setCurrentNewsId] = useState("");
  const { newsId } = useParams();
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState(""); // 新增摘要狀態
  const [content, setContent] = useState("");
  const [images, setImages] = useState({});
  const [seoDescription, setSeoDescription] = useState(""); // 新增 SEO 描述狀態
  const [seoKeywords, setSeoKeywords] = useState(""); // 新增 SEO 關鍵字狀態
  const [slug, setSlug] = useState(""); // 新增 slug 狀態
  const [coverImage, setCoverImage] = useState(null);
  const [tags, setTags] = useState([]); // 新增標籤狀態
  const [selectedTags, setSelectedTags] = useState([]); // 新增已選擇標籤狀態
  const [toc, setToc] = useState();
  const [categories, setCategories] = useState([]); // 儲存所有分類資料
  const [expandedCategories, setExpandedCategories] = useState({}); // 追蹤哪些分類是展開的
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const isInitialized = useRef(false);

  // 根據當前的 htmlContent 重新生成目錄
  const generateTableOfContents = useCallback(() => {
    const tocContainer = document.querySelector(".table_of_contents");
    tocContainer.innerHTML = ""; // 清空舊的目錄
    const tocItems = []; // 新增一個數組來存儲目錄項目

    const headings = quillRef.current.root.querySelectorAll("h1, h2");
    headings.forEach((heading) => {
      let domId = heading.id || generateRandomId(5);
      if (!heading.id) {
        heading.id = domId; // 將唯一的 dom id 添加到標題
      }

      const tocItem = document.createElement("div");
      const headingText = heading.innerText;
      const tocLink = document.createElement("a"); // 創建超連結
      tocLink.innerText = headingText;
      tocLink.href = `#${domId}`; // 設定超連結的 href
      tocItem.appendChild(tocLink); // 將超連結添加到目錄項目中
      tocContainer.appendChild(tocItem);

      const level = heading.tagName.toLowerCase(); // h1 或 h2
      tocItems.push({ text: headingText, link: `#${domId}`, level });
    });

    const tocJson = JSON.stringify(tocItems);
    setToc(tocJson);
  }, []);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("圖片壓縮失敗:", error);
      throw error;
    }
  };

  const handleImageUpload = useCallback(async (file) => {
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);

      return new Promise((resolve) => {
        reader.onload = (event) => {
          const imageId = uuidv4();
          const imageKey = `{{image:${imageId}}}`;
          const imageDataUrl = event.target.result;
          setImages((prevImages) => ({
            ...prevImages,
            [imageKey]: imageDataUrl,
          }));
          resolve({ imageKey, imageDataUrl });
        };
      });
    } catch (error) {
      console.error("圖片處理失敗:", error);
      throw error;
    }
  }, []);

  const handleCoverImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImage(reader.result); // 設定圖片的 URL
      };
      reader.readAsDataURL(file); // 將圖片轉為 Base64 URL
    }
  };

  // 新增產生隨機 ID 的函數
  const generateRandomId = (size) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < size; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  // 新增獲取標籤的函數
  const fetchTags = async () => {
    try {
      const response = await fetch(`${BACKEND_HOST}/news/tags`, {
        headers: {
          Authorization: "bestUsdtPrice",
        },
      });

      if (!response.ok) {
        throw new Error("獲取標籤失敗");
      }

      const data = await response.json();
      setTags(data || []);
      return data;
    } catch (error) {
      console.error("獲取標籤失敗:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BACKEND_HOST}/news/categories`, {
        headers: {
          Authorization: "bestUsdtPrice",
        },
      });

      if (!response.ok) {
        throw new Error("獲取分類失敗");
      }

      const data = await response.json();
      setCategories(data || []);
      return data;
    } catch (error) {
      console.error("獲取分類失敗:", error);
    }
  };

  // 新增獲取草稿或已發佈的函數
  const fetchDraft = async (id, fetchedTags, fetchedCategories) => {
    try {
      const response = await fetch(`${BACKEND_HOST}/admin/news/draft/${id}`, {
        headers: {
          Authorization: "bestUsdtPrice",
        },
      });

      if (!response.ok) {
        throw new Error("獲取草稿失敗");
      }

      const data = await response.json();

      // 更新所有狀態
      setCoverImage(data.cover || null);
      setTitle(data.title || "");
      setExcerpt(data.excerpt || "");
      setSeoDescription(data.seoDescription || "");
      setSeoKeywords(data.seoKeywords || "");
      setSlug(data.slug || "");
      setImages(data.images || {});
      if (data.sub_categories) {
        const matchedSubCategories = [];
        // 遍歷所有主分類
        for (const category of fetchedCategories) {
          // 遍歷每個主分類底下的子分類
          for (const subCategory of category.sub_categories) {
            // 檢查子分類 ID 是否在 data.sub_categories 中
            if (data.sub_categories.includes(subCategory.id)) {
              matchedSubCategories.push(subCategory);
            }
          }
        }

        setSelectedSubCategories(matchedSubCategories);
      }

      if (data.tags) {
        const matchedTags = fetchedTags.filter((tag) =>
          data.tags.includes(tag.tag_id)
        );
        setSelectedTags(matchedTags);
      }

      // console.log("data.content:", data.html);

      // 如果 quill 編輯器已經初始化，優先使用 html 欄位，如果沒有則使用 content
      if (quillRef.current) {
        // console.log("quillRef.current:", quillRef.current);
        quillRef.current.root.innerHTML = data.html || "";
      }
    } catch (error) {
      console.error("獲取草稿失敗:", error);
    }
  };

  // 新增初始化 effect
  useEffect(() => {
    const fetchData = async () => {
      const fetchedTags = await fetchTags();
      const fetchedCategories = await fetchCategories();
      if (!isInitialized.current) {
        if (!newsId) {
          const randomId = generateRandomId(20);
          setCurrentNewsId(randomId);
        } else {
          setCurrentNewsId(newsId);
          await fetchDraft(newsId, fetchedTags, fetchedCategories); // 草稿獲取
        }
        isInitialized.current = true;
      }
    };
    fetchData();
  }, [newsId]);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["blockquote", "code-block"],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ color: [] }, { background: [] }],
            ["link", "image", "video"],
            ["clean"],
            ["table-better"],
          ],
          table: false,
          "table-better": {
            toolbarTable: true,
            language: "en_US",
            menus: ["column", "row", "merge", "table", "cell", "wrap"],
          },
        },
        // formats: ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'blockquote', 'code-block', 'link', 'image', 'video', 'table-better'],
        placeholder: "在此輸入文章內容...",
      });

      quillRef.current.on("text-change", () => {
        // 使用 getSemanticHTML() 替代 root.innerHTML
        const newContent = quillRef.current.getSemanticHTML();
        setContent(newContent);

        generateTableOfContents();
      });

      // 確保 toolbar 存在後再添加 handler
      const toolbar = quillRef.current.getModule("toolbar");
      if (toolbar) {
        toolbar.addHandler("image", () => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.click();

          input.onchange = async () => {
            const file = input.files[0];
            console.log("圖片上傳處理開始");
            if (file) {
              try {
                const { imageKey, imageDataUrl } = await handleImageUpload(
                  file
                );
                const range = quillRef.current.getSelection(true);

                quillRef.current.insertEmbed(
                  range.index,
                  "image",
                  imageDataUrl
                );
                quillRef.current.setSelection(range.index + 1);

                const imageElement = quillRef.current.root.querySelector(
                  `img[src="${imageDataUrl}"]`
                );
                if (imageElement) {
                  imageElement.setAttribute("data-image-key", imageKey);
                }
              } catch (error) {
                console.error("圖片上傳失敗:", error);
              }
            }
          };
        });
      }

      // 移除 Quill 的 clipboard.addMatcher
      // 添加 document 的 paste 事件監聽器
      document.addEventListener("paste", async (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();
            if (file) {
              try {
                const { imageKey, imageDataUrl } = await handleImageUpload(
                  file
                );

                const imageElement = document.createElement("img");
                imageElement.src = imageDataUrl;
                imageElement.setAttribute("data-image-key", imageKey);
                console.log("圖片粘貼處理成功", imageKey);
              } catch (error) {
                console.error("圖片粘貼處理失敗:", error);
              }
            }
          }

          if (items[i].type === "text/html") {
            items[i].getAsString((html) => {
              // 使用 DOMParser 解析 HTML 字符串
              const parser = new DOMParser();
              const doc = parser.parseFromString(html, "text/html");

              // 查找所有的 <img> 標籤
              const imgElements = doc.querySelectorAll("img");
              imgElements.forEach(async (imgElement) => {
                const imageUrl = imgElement.src;
                if (imageUrl) {
                  try {
                    // 下載圖片並進行處理
                    const response = await fetch(imageUrl);
                    const blob = await response.blob();
                    const file = new File([blob], "image.png", {
                      type: blob.type,
                    });
                    const { imageKey, imageDataUrl } = await handleImageUpload(
                      file
                    );
                    const imageElement = document.createElement("img");
                    imageElement.src = imageDataUrl;
                    imageElement.setAttribute("data-image-key", imageKey);

                    console.log("圖片插入成功", imageKey);
                  } catch (error) {
                    console.error("圖片處理失敗:", error);
                  }
                }
              });
            });
          }
        }
      });
    }
  }, [handleImageUpload, generateTableOfContents]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleExcerptChange = (e) => {
    setExcerpt(e.target.value);
  };

  const handleSeoDescriptionChange = (e) => {
    setSeoDescription(e.target.value);
  };

  const handleSeoKeywordsChange = (e) => {
    setSeoKeywords(e.target.value);
  };

  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };
  const publishArticle = async (publish = true) => {
    const htmlContent = content;
    const selectedTagIds = selectedTags.map((tag) => tag.tag_id);
    // console.log("檢查發布的html:", htmlContent);
    const payload = {
      title,
      excerpt,
      html: htmlContent,
      images,
      slug,
      publish,
      news_id: currentNewsId,
      cover: coverImage,
      tags: selectedTagIds,
      toc,
      sub_categories: selectedSubCategories.map((cat) => cat.id),
    };

    try {
      const response = await fetch(`${BACKEND_HOST}/admin/publish`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bestUsdtPrice",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("文章發布失敗");
      }

      if (publish) {
        alert("文章發布成功！");
      } else {
        alert("文章保存成功！");
      }
    } catch (error) {
      console.error("文章發布失敗:", error);
    }
  };

  // 新增 reload 按鈕的處理函數
  const handleReloadTags = () => {
    fetchTags();
  };

  // 新增移除標籤的函數
  const removeTag = (tag) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  // 新增選擇標籤的函數
  const addTag = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags((prevTags) => [...prevTags, tag]);
    }
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const selectSubCategory = (subCategory) => {
    setSelectedSubCategories((prev) => {
      // 檢查是否已經選擇了這個子分類
      const isSelected = prev.some((cat) => cat.id === subCategory.id);
      if (isSelected) {
        // 如果已選擇，則移除
        return prev.filter((cat) => cat.id !== subCategory.id);
      } else {
        // 如果未選擇，則添加
        return [...prev, subCategory];
      }
    });
  };

  const removeSubCategory = (categoryId) => {
    setSelectedSubCategories((prev) =>
      prev.filter((cat) => cat.id !== categoryId)
    );
  };

  const handleReloadCategories = () => {
    fetchCategories();
  };

  return (
    <>
      <Helmet>
        <title>USDT比價網 | 新聞編輯</title>
      </Helmet>
      <div className="news-edit-body">
        <div className="article-edit-page">
          <h1 className="page-title">編輯文章</h1>
          <div className="editor-container">
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              className="title-input"
              placeholder="請輸入文章標題"
            />
            <textarea
              value={excerpt}
              onChange={handleExcerptChange}
              className="excerpt-input"
              placeholder="請輸入文章摘要"
            />
            <div className="image-upload-container">
              <input
                type="file"
                // accept="image/*"
                onChange={handleCoverImageUpload}
                className="image-upload-input"
              />
              {!coverImage && <p>新聞封面，請上傳照片</p>}
              {coverImage && (
                <div>
                  <h3>封面預覽：</h3>
                  <img
                    src={coverImage}
                    alt="Uploaded Preview"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      marginTop: "10px",
                    }}
                  />
                </div>
              )}
            </div>
            <textarea
              value={seoDescription}
              onChange={handleSeoDescriptionChange}
              className="seo-description-input"
              placeholder="請輸入SEO描述"
            />
            <textarea
              value={seoKeywords}
              onChange={handleSeoKeywordsChange}
              className="seo-keywords-input"
              placeholder="請輸入SEO關鍵字，請用 ,隔開"
            />
            <input
              type="text"
              value={slug}
              onChange={handleSlugChange}
              className="slug-input"
              placeholder="請輸入新聞網址結尾，例如 crypto-signup-comparison"
            />
            <div className="news-editor-tags-container">
              <div className="news-editor-tags-container-header">
                <h3>標籤設定</h3>
                <button onClick={handleReloadTags}>重新載入標籤</button>
              </div>
              <div className="available-tags">
                {" "}
                可選標籤
                {tags.map((tag) => (
                  <div
                    key={tag.tag_id}
                    className="tag-item"
                    onClick={() => addTag(tag)}
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
              <div className="selected-tags">
                {" "}
                已選標籤
                {selectedTags.map((tag) => (
                  <div key={tag.tag_id} className="selected-tag">
                    {tag.name}
                    <button onClick={() => removeTag(tag)}>×</button>
                  </div>
                ))}
              </div>
            </div>
            <div className="news-editor-category-container">
              <div className="news-editor-category-container-header">
                <h3>文章分類設定</h3>
                <button onClick={handleReloadCategories}>重新載入分類</button>
              </div>
              <div className="available-categories">
                <div className="categories-list">
                  {categories.map((category) => (
                    <div key={category.id} className="category-group">
                      <div
                        className="parent-category"
                        onClick={() => toggleCategory(category.id)}
                      >
                        <span className="expand-icon">
                          {expandedCategories[category.id] ? "▼" : "▶"}
                        </span>
                        {category.name}
                      </div>
                      {expandedCategories[category.id] && (
                        <div className="sub-categories">
                          {category.sub_categories.map((subCategory) => (
                            <div
                              key={subCategory.id}
                              className={`sub-category-item ${
                                selectedSubCategories.some(
                                  (cat) => cat.id === subCategory.id
                                )
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => selectSubCategory(subCategory)}
                            >
                              {subCategory.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="selected-category">
                已選子分類：
                {selectedSubCategories.map((subCategory) => (
                  <div
                    key={subCategory.id}
                    className="selected-subcategory-item"
                  >
                    {subCategory.name}
                    <button onClick={() => removeSubCategory(subCategory.id)}>
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
            自動生成目錄
            <div className="table_of_contents"></div>
            <div ref={editorRef} className="content-editor custom-quill"></div>
            <div className="button-group">
              <button
                type="button"
                className="editor-button submit-button"
                onClick={() => publishArticle(false)}
              >
                保存文章
              </button>
              <button
                type="button"
                className="editor-button publish-button"
                onClick={() => publishArticle(true)}
              >
                發布文章
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewsEditPage;
