import React, { useState } from "react";
import { createBrowserRouter } from "react-router-dom";
import ExchangeRates from "../components/exchange/ExchangeRates";
import LoginPage from "../components/admin/LoginPage";
import NewsEditPage from "../components/admin/NewsEditPage";
import NewsHome from "../components/news/NewsHome";
import NewsArticle from "../components/news/NewsArticle";
import Navigation from "../components/Navigation";
import Sidebar from "../components/admin/AdminDashboardSidebar";
import ManagePosts from "../components/admin/ManagePosts";
import NewsTags from "../components/admin/tags";
import NewsCategories from "../components/admin/categories";

function RequireAuth({ children, isAuthenticated, setAuth }) {
  if (!isAuthenticated) {
    return <LoginPage setAuth={setAuth} />;
  }
  return children;
}

// 新增 AdminLayout 組件
function AdminLayout({ children }) {
  return (
    <>
      <Sidebar />
      {children}
    </>
  );
}

export const AppRouter = () => {
  const [isAuthenticated, setAuth] = useState(false);

  return createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ExchangeRates />
        </>
      ),
    },
    {
      path: "/admin",
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <AdminLayout>
            <ManagePosts />
          </AdminLayout>
        </RequireAuth>
      ),
    },
    {
      path: "/admin/manage-news",
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <AdminLayout>
            <ManagePosts />
          </AdminLayout>
        </RequireAuth>
      ),
    },
    {
      path: "/admin/login",
      element: <LoginPage setAuth={setAuth} />,
    },
    {
      path: "/admin/news/edit",
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <AdminLayout>
            <NewsEditPage />
          </AdminLayout>
        </RequireAuth>
      ),
    },
    {
      path: "/admin/news/edit/:newsId",
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <AdminLayout>
            <NewsEditPage />
          </AdminLayout>
        </RequireAuth>
      ),
    },
    {
      path: "/admin/news/tags",
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <AdminLayout>
            <NewsTags />
          </AdminLayout>
        </RequireAuth>
      ),
    },
    {
      path: "/admin/news/categories",
      element: (
        <RequireAuth isAuthenticated={isAuthenticated} setAuth={setAuth}>
          <AdminLayout>
            <NewsCategories />
          </AdminLayout>
        </RequireAuth>
      ),
    },
    {
      path: "/news",
      element: (
        <>
          <Navigation />
          <NewsHome />
        </>
      ),
    },
    {
      path: "/news?tag=:tagId",
      element: (
        <>
          <Navigation />
          <NewsHome />
        </>
      ),
    },
    {
      path: "/news?sub_category=:sub_categoryId",
      element: (
        <>
          <Navigation />
          <NewsHome />
        </>
      ),
    },
    {
      path: "/news/:slug",
      element: (
        <>
          <NewsArticle />
        </>
      ),
    },
  ]);
};
