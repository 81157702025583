import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const API_BASE_URL = process.env.REACT_APP_BACKEND_HOST;

function LoginPage({ setAuth }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        setAuth(true);
        localStorage.setItem('authToken', data.token);
        navigate('/admin');
      } else {
        const errorData = await response.json();
        setError(errorData.message || '登入失敗');
      }
    } catch (error) {
      setError('帳號或密碼錯誤');
    }
  };

  React.useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // 以秒為單位
        if (decodedToken.exp > currentTime) {
          setAuth(true);
        } else {
          localStorage.removeItem('authToken'); // 移除過期的 token
          setAuth(false);
        }
      } catch (error) {
        console.error('Token 解析錯誤:', error);
        setAuth(false);
      }
    }
  }, [setAuth]);


  return (
    <>
      <Helmet>
        <title>USDT比價網 | 後台管理</title>
      </Helmet>
    <div className="login-page">
      <h1>登入</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">帳號:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">密碼:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">登入</button>
      </form>
    </div>
    </>
  );
}

export default LoginPage; 