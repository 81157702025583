import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => {
        // 初始化時從 localStorage 獲取主題
        return localStorage.getItem('theme') || 'light';
    });

    useEffect(() => {
        // 主題變化時更新 localStorage
        localStorage.setItem('theme', theme);
    }, [theme]);
    const toggleTheme = (selectedTheme) => {
        setTheme(selectedTheme);
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);