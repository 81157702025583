// admin/tags.js
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./categories.css";

const NewsCategories = () => {
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [editCategoryIndex, setEditCategoryIndex] = useState(-1);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [initialCategories, setInitialCategories] = useState([]);
  const [editSubcategoryIndex, setEditSubcategoryIndex] = useState(-1);
  const [editSubcategoryName, setEditSubcategoryName] = useState("");
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newSubcategoryName, setNewSubcategoryName] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${BACKEND_HOST}/news/categories`);
        if (response.ok) {
          const data = await response.json();
          const categoriesWithSubcategories = data.map((category) => ({
            ...category,
            sub_categories: category.sub_categories || [],
          }));
          console.log(
            "categoriesWithSubcategories:",
            categoriesWithSubcategories
          );
          setCategories(categoriesWithSubcategories);
          setInitialCategories(categoriesWithSubcategories);
        } else {
          throw new Error("獲取分類失敗");
        }
      } catch (error) {
        console.error("獲取分類時發生錯誤:", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCategories();
  }, [BACKEND_HOST]);

  const addCategory = async () => {
    console.log("addCategory in", newCategory);
    if (newCategory) {
      let newCategoryData = {
        name: newCategory,
        order: categories.length, // 順序從 0 開始
        id: Math.random().toString(36).substring(2, 12),
      };
      console.log("newCategoryData:", newCategoryData);
      // 發送 POST 請求到後端 API
      const response = await fetch(`${BACKEND_HOST}/admin/categories`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bestUsdtPrice",
        },
        body: JSON.stringify(newCategoryData),
      });

      if (response.ok) {
        setCategories([...categories, newCategoryData]);
        setNewCategory("");
      } else {
        // 處理錯誤情況
        console.error("新增分類失敗");
      }
    }
  };

  const editCategory = async (categoryId, index) => {
    const updatedCategories = [...categories];
    const category = updatedCategories[index];

    category.name = editCategoryName; // 更新分類名稱

    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/categories/${category.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bestUsdtPrice",
          },
          body: JSON.stringify(category),
        }
      );

      if (response.ok) {
        setCategories(updatedCategories);
        setEditCategoryIndex(-1);
        setEditCategoryName("");
      } else {
        console.error("更新分類失敗");
      }
    } catch (error) {
      console.error("更新分類時發生錯誤:", error);
    }
  };

  const handleDragEnd = (result) => {
    const { source, destination, type } = result;
    if (!destination) return;

    const reorderedCategories = Array.from(categories);

    if (type === "category") {
      // 處理主分類的拖曳
      const [movedCategory] = reorderedCategories.splice(source.index, 1);
      reorderedCategories.splice(destination.index, 0, movedCategory);
      setCategories(reorderedCategories);
    } else if (type === "subcategory") {
      // 處理子分類的拖曳
      const sourceParentId = source.droppableId;
      const destParentId = destination.droppableId;

      if (sourceParentId !== destParentId) {
        return; // 不允許跨分類移動
      }

      const categoryIndex = reorderedCategories.findIndex(
        (cat) => cat.id === sourceParentId
      );

      const subcategories = Array.from(
        reorderedCategories[categoryIndex].sub_categories
      );
      const [movedSubcategory] = subcategories.splice(source.index, 1);
      subcategories.splice(destination.index, 0, movedSubcategory);

      reorderedCategories[categoryIndex].sub_categories = subcategories;
      setCategories(reorderedCategories);
    }
  };

  const deleteCategory = async (index) => {
    const categoryToDelete = categories[index];
    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/categories/${categoryToDelete.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bestUsdtPrice",
          },
        }
      );
      if (response.ok) {
        const updatedCategories = categories.filter((_, i) => i !== index);
        setCategories(updatedCategories);
      } else {
        console.error("刪除分類失敗");
      }
    } catch (error) {
      console.error("刪除分類出錯", error);
    }
  };

  const saveAllCategories = async () => {
    console.log("saveAllCategories, categories:", categories);
    console.log("saveAllCategories, initialCategories:", initialCategories);

    const updatedCategories = categories.map((category, index) => ({
      ...category,
      order: index,
    }));
    setCategories(updatedCategories);

    const response = await fetch(`${BACKEND_HOST}/admin/categories`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bestUsdtPrice",
      },
      body: JSON.stringify(updatedCategories),
    });

    if (response.ok) {
      setInitialCategories(updatedCategories); // 更新 initialCategories
      console.log("分類順序儲存成功");
    } else {
      console.error("儲存分類順序失敗");
    }
  };

  const isOrderChanged =
    JSON.stringify(categories) !== JSON.stringify(initialCategories);

  const editSubcategory = (categoryId, subIndex) => {
    const category = categories.find((cat) => cat.id === categoryId);
    setEditingCategoryId(categoryId);
    setEditSubcategoryIndex(subIndex);
    setEditSubcategoryName(category.sub_categories[subIndex].name);
  };

  const saveSubcategory = async () => {
    const categoryIndex = categories.findIndex(
      (cat) => cat.id === editingCategoryId
    );
    const updatedCategories = [...categories];
    const category = updatedCategories[categoryIndex];

    // 更新子分類名稱
    category.sub_categories[editSubcategoryIndex].name = editSubcategoryName;

    // 發送整個分類的更新請求
    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/categories/${category.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bestUsdtPrice",
          },
          body: JSON.stringify(category),
        }
      );

      if (response.ok) {
        setCategories(updatedCategories);
        setEditingCategoryId(null);
        setEditSubcategoryIndex(-1);
        setEditSubcategoryName("");
      } else {
        console.error("更新子分類失敗");
      }
    } catch (error) {
      console.error("更新子分類時發生錯誤:", error);
    }
  };

  const addSubcategory = async (categoryId) => {
    if (!newSubcategoryName.trim()) return;

    const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
    const updatedCategories = [...categories];
    const category = updatedCategories[categoryIndex];

    const newSubcategory = {
      id: Math.random().toString(36).substring(2, 12),
      name: newSubcategoryName,
      order: category.sub_categories ? category.sub_categories.length : 0,
    };

    // 確保 sub_categories 陣列存在
    if (!category.sub_categories) {
      category.sub_categories = [];
    }

    category.sub_categories.push(newSubcategory);
    console.log("addSubcategory:", JSON.stringify(category));
    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/categories/${categoryId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bestUsdtPrice",
          },
          body: JSON.stringify(category),
        }
      );

      if (response.ok) {
        setCategories(updatedCategories);
        setNewSubcategoryName("");
        setSelectedCategoryId(null);
      } else {
        console.error("新增子分類失敗");
      }
    } catch (error) {
      console.error("新增子分類時發生錯誤:", error);
    }
  };

  const deleteSubcategory = async (categoryId, subcategoryId) => {
    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/categories/${categoryId}/sub_categories/${subcategoryId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bestUsdtPrice",
          },
        }
      );

      if (response.ok) {
        const updatedCategories = categories.map((category) => {
          if (category.id === categoryId) {
            return {
              ...category,
              sub_categories: category.sub_categories.filter(
                (sub) => sub.id !== subcategoryId
              ),
            };
          }
          return category;
        });
        setCategories(updatedCategories);
      } else {
        console.error("刪除子分類失敗");
      }
    } catch (error) {
      console.error("刪除子分類時發生錯誤:", error);
    }
  };

  return (
    <div className="categories-container">
      <h1>分類管理</h1>
      {isLoading ? (
        <div>載中...</div>
      ) : (
        <div>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="輸入分類名稱"
          />
          <button onClick={addCategory}>新增分類</button>
          說明: 子分類無法拖曳到其他分類
        </div>
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="categories" type="category">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={category.id}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="category-item">
                        <span className="drag-handle">調整順序</span>
                        {editCategoryIndex === index ? (
                          <div className="edit-category">
                            <input
                              type="text"
                              value={editCategoryName}
                              onChange={(e) =>
                                setEditCategoryName(e.target.value)
                              }
                            />
                            <button
                              onClick={() => editCategory(category.id, index)}
                            >
                              保存
                            </button>
                            <button onClick={() => setEditCategoryIndex(-1)}>
                              取消
                            </button>
                          </div>
                        ) : (
                          <>
                            <span>{category.name}</span>
                            <button
                              onClick={() => {
                                setEditCategoryIndex(index);
                                setEditCategoryName(category.name);
                              }}
                            >
                              編輯
                            </button>
                          </>
                        )}
                        <button
                          onClick={() => setSelectedCategoryId(category.id)}
                        >
                          新增子分類
                        </button>
                        <button onClick={() => deleteCategory(index)}>
                          刪除
                        </button>
                      </div>

                      {/* 子分類列表 */}
                      <Droppable droppableId={category.id} type="subcategory">
                        {(provided) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {category.sub_categories &&
                              category.sub_categories.map(
                                (subcategory, subIndex) => (
                                  <Draggable
                                    key={subcategory.id}
                                    draggableId={subcategory.id}
                                    index={subIndex}
                                  >
                                    {(provided) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="subcategory-item"
                                      >
                                        <span className="drag-handle">☰</span>
                                        {editingCategoryId === category.id &&
                                        editSubcategoryIndex === subIndex ? (
                                          <div className="edit-subcategory">
                                            <input
                                              type="text"
                                              value={editSubcategoryName}
                                              onChange={(e) =>
                                                setEditSubcategoryName(
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <button onClick={saveSubcategory}>
                                              保存
                                            </button>
                                          </div>
                                        ) : (
                                          <span>{subcategory.name}</span>
                                        )}
                                        <button
                                          onClick={() =>
                                            editSubcategory(
                                              category.id,
                                              subIndex
                                            )
                                          }
                                        >
                                          編輯
                                        </button>
                                        <button
                                          onClick={() =>
                                            deleteSubcategory(
                                              category.id,
                                              subcategory.id
                                            )
                                          }
                                        >
                                          刪除
                                        </button>
                                      </li>
                                    )}
                                  </Draggable>
                                )
                              )}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                      {selectedCategoryId === category.id && (
                        <div className="add-subcategory">
                          <input
                            type="text"
                            value={newSubcategoryName}
                            onChange={(e) =>
                              setNewSubcategoryName(e.target.value)
                            }
                            placeholder="輸入子分類名稱"
                          />
                          <button onClick={() => addSubcategory(category.id)}>
                            確認新增
                          </button>
                          <button onClick={() => setSelectedCategoryId(null)}>
                            取消
                          </button>
                        </div>
                      )}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <button onClick={saveAllCategories} disabled={!isOrderChanged}>
        儲存分類順序
      </button>
    </div>
  );
};

export default NewsCategories;
