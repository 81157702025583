import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { AppRouter } from './routes';
// import ReactGA from 'react-ga';

// // 初始化 GA
// ReactGA.initialize('G-5M4T1SW6PS');

function App() {
  return (
    <ThemeProvider>
      <RouterProvider router={AppRouter()} />
    </ThemeProvider>
  );
}

export default App;