import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./NewsArticle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faRectangleXmark } from "@fortawesome/free-regular-svg-icons";
import { faShareNodes, faList } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../contexts/ThemeContext";
import Navigation from "../Navigation";
import Cookies from "js-cookie"; // 確保引入 Cookies 庫

const NewsArticle = () => {
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
  const { theme } = useTheme();
  const { slug } = useParams();

  const [htmlContent, setHtmlContent] = useState("");
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [publishedDate, setPublishedDate] = useState("");
  const [views, setViews] = useState(0);
  const [coverImage, setCoverImage] = useState("");
  const [sidebarLoading, setSidebarLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagLoading, setTagsLoading] = useState(true);
  const [isViewed, setIsViewed] = useState(false); // 是否已記錄觀看
  const [newsData, setNewsData] = useState(null);
  const [toc, setToc] = useState([]);
  const [isTocOpen, setIsTocOpen] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "light") {
      root.style.setProperty("--theme-bg", "url(/assets/light.svg)");
      root.style.setProperty("--news-body-color", "#000000");
      root.style.setProperty("--news-cover-image-bg-color", "#000000");
      root.style.setProperty("--news-container-bg", "#FFFFFF");
      root.style.setProperty(
        "--news-container-border",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty(
        "--news-sidebar-border",
        "rgba(125, 162, 212, 0.2)"
      );
      root.style.setProperty("--news-body-h1-color", "#000000");
      root.style.setProperty("--news-body-article-title-color", "#000000");
      root.style.setProperty("--news-body-publish-date-color", "#888");
      root.style.setProperty("--news-body-content-bg", "#FFFFFF");
      root.style.setProperty("--news-body-ul-color", "#CCCCCC");
      root.style.setProperty("--news-body-sidebar-title-color", "#1E293B");
      root.style.setProperty("--news-body-main-content-p-color", "#000000");
      root.style.setProperty("--news-body-sidebar-a-color", "#000000");
      root.style.setProperty("--news-body-a-color", "#fcd535");
      root.style.setProperty("--news-body-th-border", "#e0e0e0");
      root.style.setProperty(
        "--news-content-divider-border",
        "rgba(71, 85, 105, 0.2)"
      );
      root.style.setProperty("--news-published-date-color", "#FFFFFF");
      root.style.setProperty("--clock-icon-color", "#000000");
      root.style.setProperty("--news-article-title-overlay-color", "#FFFFFF");
      root.style.setProperty(
        "--news-label-button-border",
        "rgba(71, 85, 105, 0.6)"
      );
      root.style.setProperty("--news-label-button-color", "#334155");
      root.style.setProperty("--news-article-nav-wrapper-bg", "#FFFFFF");
      root.style.setProperty("--news-toc-bg", "#FFFFFF");
      root.style.setProperty("--news-toc-border", "rgba(71, 85, 105, 0.2)");
      root.style.setProperty("--news-toc-title-color", "#334155");
      root.style.setProperty("--news-toc-h2-color", "rgba(71, 85, 105, 0.7)");
      root.style.setProperty(
        "--news-toc-item-hover-bg",
        "rgba(51, 65, 85, 0.1)"
      );
    } else {
      root.style.setProperty("--theme-bg", "url(/assets/dark.svg)");
      root.style.setProperty("--news-body-color", "#FFFFFF");
      root.style.setProperty("--news-cover-image-bg-color", "#000000");
      root.style.setProperty("--news-container-bg", "rgba(24, 26, 31, 1)");
      root.style.setProperty(
        "--news-container-border",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty(
        "--news-sidebar-border",
        "rgba(125, 162, 212, 0.2)"
      );
      root.style.setProperty("--news-body-h1-color", "#FFFFFF");
      root.style.setProperty("--news-body-article-title-color", "#FFFFFF");
      root.style.setProperty("--news-body-publish-date-color", "#888");
      root.style.setProperty("--news-body-content-bg", "#181a1f");
      root.style.setProperty("--news-body-ul-color", "#CCCCCC");
      root.style.setProperty(
        "--news-body-sidebar-title-color",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty("--news-body-main-content-p-color", "#e0e0e0");
      root.style.setProperty("--news-body-sidebar-a-color", "#FFFFFF");
      root.style.setProperty("--news-body-a-color", "#fcd535");
      root.style.setProperty("--news-body-th-border", "#e0e0e0");
      root.style.setProperty("--news-content-divider-border", "#333");
      root.style.setProperty("--news-published-date-color", "#FFFFFF");
      root.style.setProperty("--clock-icon-color", "#FFFFFF");
      root.style.setProperty(
        "--news-label-button-border",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty("--news-label-button-color", "#FFFFFF");
      root.style.setProperty("--news-article-title-overlay-color", "#FFFFFF");
      root.style.setProperty("--news-article-nav-wrapper-bg", "#000000");
      root.style.setProperty("--news-toc-bg", "#181a1f");
      root.style.setProperty("--news-toc-border", "#475569");
      root.style.setProperty("--news-toc-title-color", "#FFFFFF");
      root.style.setProperty("--news-toc-h2-color", "rgba(255, 255, 255, 0.7)");
      root.style.setProperty(
        "--news-toc-item-hover-bg",
        "rgba(255, 255, 255, 0.2)"
      );
    }
  }, [theme]);

  // 從 localStorage 獲取共用資料
  useEffect(() => {
    const getSharedData = () => {
      const cached = localStorage.getItem("newsSharedData");
      if (cached) {
        const { data, timestamp } = JSON.parse(cached);
        // 檢查是否過期（1小時）
        if (Date.now() - timestamp < 3600000) {
          // 設置熱門文章
          setArticles(data.popularArticles);
          setSidebarLoading(false);
          // 設置標籤
          setTags(data.tags);
          setTagsLoading(false);
          return true;
        }
      }
      return false;
    };

    // 如果無法從 localStorage 獲取資料，則發送請求
    const fetchData = async () => {
      try {
        const [popularRes, tagsRes] = await Promise.all([
          axios.get(`${BACKEND_HOST}/news?sort_by=views&limit=3`),
          axios.get(`${BACKEND_HOST}/news/tags`),
        ]);

        setArticles(popularRes.data);
        setTags(tagsRes.data);
      } catch (error) {
        console.error("無法載入側邊欄資料:", error);
      } finally {
        setSidebarLoading(false);
        setTagsLoading(false);
      }
    };

    // 如果無法從 localStorage 獲取資料，則發送請求
    if (!getSharedData()) {
      fetchData();
    }
  }, [BACKEND_HOST]);

  // 從 GCS 獲取 HTML 內容
  useEffect(() => {
    const fetchHtmlContent = async () => {
      let news_id = null;
      try {
        const response = await axios.get(`${BACKEND_HOST}/news/${slug}`);
        news_id = response.data.news.news_id;
        setNewsData(response.data.news);
        setPublishedDate(response.data.news.published_at);
        setCoverImage(response.data.news.cover);
        setViews(response.data.news.views);
        setToc(JSON.parse(response.data.news.toc || "[]"));

        const htmlResponse = await axios.get(
          `https://storage.googleapis.com/www.usdtprice.com/public/html/${news_id}?d=0124`
        );
        setHtmlContent(htmlResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("無法取得新聞 ID 或載入 HTML 內容:", error);
      }
    };

    fetchHtmlContent();
  }, [BACKEND_HOST, slug]);

  // 添加滾動監聽
  useEffect(() => {
    if (!htmlContent) return;
    const contentElement = document.querySelector(".news-body");
    if (!contentElement) return;

    let hasTriggered = false; // 新增變數來追蹤是否已經觸發過

    const handleScroll = () => {
      const contentHeight = contentElement.scrollHeight;
      const scrolledPosition =
        contentElement.scrollTop + contentElement.clientHeight;
      const threshold = contentHeight * 0.3;

      // 檢查是否已經觸發過
      const viewedKey = `viewed_${slug}`;
      const viewedTimestamp = Cookies.get(viewedKey);

      if (
        !hasTriggered &&
        scrolledPosition >= threshold &&
        (!viewedTimestamp || Date.now() - viewedTimestamp > 24 * 60 * 60 * 1000)
      ) {
        hasTriggered = true; // 設定為已觸發
        setIsViewed(true);
        axios.post(`${BACKEND_HOST}/news/view`, { slug: slug }); // 傳遞文章 ID，記錄用戶行為
        // 設定 cookie，1 天後過期
        Cookies.set(viewedKey, Date.now(), { expires: 1 }); // 修改為一天
      }
    };

    contentElement.addEventListener("scroll", handleScroll);

    return () => {
      contentElement.removeEventListener("scroll", handleScroll);
    };
  }, [BACKEND_HOST, htmlContent, slug, isViewed]);

  if (loading || sidebarLoading) {
    return <div>載入中...</div>;
  }

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta name="description" content="${seoDescription}" /> */}
        {/* <meta name="keywords" content="${seoKeywords}" /> */}

        <meta property="og:url" content="REPLACE_WITH_WEBSITE_URL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={newsData.title} />
        {/* <meta property="og:description" content="${seoDescription}" /> */}
        {/* <meta property="og:image" content="REPLACE_WITH_IMAGE_URL" /> */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="usdtprice.com" />
        {/* <meta property="twitter:url" content="REPLACE_WITH_WEBSITE_URL" /> */}
        <meta name="twitter:title" content={newsData.title} />
        {/* <meta property="twitter:description" content="${seoDescription}" /> */}
        {/* <meta name="twitter:image" content="REPLACE_WITH_IMAGE_URL" /> */}
        <title>${newsData.title}</title>
      </Helmet>

      <div className="news-article-body">
        <Navigation />
      </div>

      <div className="news-body">
        <div className="news-cover-image">
          <img
            src={coverImage}
            className="news-cover-image-img"
            alt="cover-image"
          />
        </div>
        <div className="news-container">
          <div className="main-content-column">
            <div className="news-info">
              <div className="news-info-left">
                <FontAwesomeIcon
                  icon={faClock}
                  className="fa-clock"
                  style={{ color: "var(--clock-icon-color)" }}
                />{" "}
                <div className="news-published-date">{publishedDate}</div>
              </div>

              <div className="news-info-right">
                <div className="news-views">{views} views</div>
                <div className="share-icon">
                  <FontAwesomeIcon icon={faShareNodes} className="fa-share" />
                </div>
              </div>
            </div>
            <hr className="news-content-divider" />

            <div className="main-content">
              <h1 class="article-title">{newsData.title}</h1>
              <div class="excerpt">{newsData.excerpt}</div>
              <div
                class="content"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              ></div>
            </div>
          </div>

          {/* 最新文章列表 */}
          <div className="news-sidebar">
            <div className="news-sidebar-title">熱門文章</div>
            {sidebarLoading ? (
              <div>載入熱門文章中...</div>
            ) : (
              articles.map((article, index) => (
                <a
                  key={index}
                  href={article.URL}
                  className="news-sidebar-article-card"
                >
                  <div className="news-sidebar-article-images">
                    <img
                      src={article.cover}
                      alt={article.title}
                      className="news-sidebar-article-main-image"
                    />
                    <div className="news-sidebar-article-mask"></div>

                    <h2 className="news-sidebar-article-title-overlay">
                      {article.title}
                    </h2>
                  </div>
                </a>
              ))
            )}

            {/* 關鍵字 */}
            <div className="news-sidebar-keyword-title">關鍵字</div>
            <div className="news-labels">
              {tagLoading ? (
                <div>載入關鍵字中...</div>
              ) : (
                tags.map((tag, index) => (
                  <a
                    key={index}
                    href={`/news?tag=${tag.tag_id}`}
                    className="news-label"
                  >
                    <button className="news-label-button">{tag.name}</button>
                  </a>
                ))
              )}
            </div>
          </div>
        </div>

        {!isTocOpen && (
          <div className="news-toc-menu" onClick={() => setIsTocOpen(true)}>
            <div className="news-toc-menu-title">
              <FontAwesomeIcon icon={faList} className="fa-list" /> 文章索引
            </div>
          </div>
        )}
        {isTocOpen && (
          <div className="news-toc">
            <div className="news-toc-header">
              <div className="news-toc-title">文章索引</div>
              <div
                className="news-toc-close-button"
                onClick={() => setIsTocOpen(false)}
              >
                <FontAwesomeIcon icon={faRectangleXmark} className="fa-xmark" />
              </div>
            </div>
            <div className="news-toc-content">
              {toc &&
                toc.map((item, index) => {
                  if (item.level === "h1") {
                    return (
                      <div key={index}>
                        <a href={`${item.link}`} className="toc-item toc-h1">
                          {index + 1}. {item.text}
                        </a>
                      </div>
                    );
                  } else if (item.level === "h2") {
                    return (
                      <div key={index}>
                        <a href={`${item.link}`} className="toc-item toc-h2">
                          - {item.text}
                        </a>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewsArticle;
