import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "../contexts/ThemeContext";
import "./Navigation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-regular-svg-icons";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

const Navigation = () => {
  const { theme, toggleTheme } = useTheme();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // 控制選單顯示

  function toggleMenu() {
    // 實現顯示/隱藏選單的邏輯
    setIsMenuOpen((prev) => !prev); // 切換選單顯示狀態
  }

  useEffect(() => {
    const root = document.documentElement;

    if (theme === "light") {
      root.style.setProperty("--usdt-nav-wrapper-bg", "url(/assets/light.svg)");
      root.style.setProperty("--theme-bg", "url(/assets/light.svg)");
      root.style.setProperty("--website-text-color", `#009393`);
      root.style.setProperty("--text-color", `rgba(212,161,0,1)`);
      root.style.setProperty("--text-color-inactive", "#3A3A3A");
      root.style.setProperty(
        "--theme-toggle-container-border",
        "rgba(30, 30, 30, 0.2)"
      );
      root.style.setProperty(
        "--theme-toggle-button-color",
        "rgba(30, 30, 30, 0.5)"
      );
      root.style.setProperty(
        "--theme-toggle-button-active-bg",
        "rgba(217, 217, 217, 0.5)"
      );
      root.style.setProperty(
        "--theme-toggle-button-icon-color",
        "rgba(30, 30, 30, 0.5)"
      );
      root.style.setProperty(
        "--theme-toggle-button-active-color",
        "rgba(20, 27, 52, 1)"
      );
      root.style.setProperty("--nav-menu-bg", "#FFFFFF");
      root.style.setProperty("--nav-menu-color", "#000000");
      root.style.setProperty("--nav-menu-list-bg", "rgba(217, 217, 217, 0.3)");
    } else {
      root.style.setProperty("--usdt-nav-wrapper-bg", "url(/assets/dark.svg)");
      root.style.setProperty("--theme-bg", "url(/assets/dark.svg)");
      root.style.setProperty("--website-text-color", `#009393`);
      root.style.setProperty("--text-color", `#D6C6AB`);
      root.style.setProperty("--text-color-inactive", "#FFFFFF");
      root.style.setProperty(
        "--theme-toggle-container-border",
        "rgba(217, 217, 217, 0.5)"
      );
      root.style.setProperty("--theme-toggle-button-color", "#FFFFFF");
      root.style.setProperty(
        "--theme-toggle-button-active-bg",
        "rgba(217, 217, 217, 0.5)"
      );
      root.style.setProperty(
        "--theme-toggle-button-icon-color",
        "rgba(217, 217, 217, 0.5)"
      );
      root.style.setProperty("--theme-toggle-button-active-color", "#FFFFFF");
      root.style.setProperty("--nav-menu-bg", "#1E293B");
      root.style.setProperty("--nav-menu-color", "#FFFFFF");
      root.style.setProperty("--nav-menu-list-bg", "rgba(24, 26, 31, 0.3)");
    }
  }, [theme]);

  return (
    <div className="usdt-nav-wrapper">
      <nav className="usdt-navbar">
        <div className="usdt-nav-left">
          <div className="usdt-coin-container">
            <Link to="/" className="usdt-coin-container-link">
              <img
                src="/assets/usdt-logo.png"
                alt="USDT Logo"
                className="usdt-coin-logo"
              />
              <span className="usdt-coin-website">USDT比價網</span>
            </Link>
          </div>
          <div className="usdt-price-container">
            <Link
              to="/"
              className={`usdt-coin-container-link ${
                location.pathname === "/" ? "active" : "inactive"
              }`}
            >
              <span className="usdt-coin-title">即時比價</span>
            </Link>
          </div>
          <div className="usdt-news-container">
            <Link
              to="/news"
              className={`usdt-news-link ${
                location.pathname.startsWith("/news") ? "active" : "inactive"
              }`}
            >
              資訊分享
            </Link>
          </div>
        </div>

        <div className="usdt-nav-right">
          <div className="theme-toggle-container pc-only">
            <button
              onClick={() => toggleTheme("light")}
              className={`theme-toggle-button ${
                theme === "light" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faSun} className="icon" />
            </button>
            <button
              onClick={() => toggleTheme("dark")}
              className={`theme-toggle-button ${
                theme === "dark" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faMoon} className="icon" />
            </button>
          </div>

          <div class="usdt-nav-right">
            <div className="menu-button" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faBars} className="icon" />
            </div>
          </div>
        </div>
      </nav>

      {/* 選單畫面 */}
      {isMenuOpen && (
        <div className="menu">
          {/* Header */}
          <div className="menu-header">
            <div className="usdt-coin-container">
              <Link to="/" className="usdt-coin-container-link">
                <img
                  src="/assets/usdt-logo.png"
                  alt="USDT Logo"
                  className="usdt-coin-logo"
                />
                <span className="usdt-coin-website">USDT比價網</span>
              </Link>
            </div>
            <button className="menu-close-button" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faX} className="icon" />
            </button>
          </div>

          {/* Light/Dark Mode Toggle */}
          <div className="theme-toggle-container mobile-only">
            <button
              onClick={() => toggleTheme("light")}
              className={`theme-toggle-button ${
                theme === "light" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faSun} className="icon" />
            </button>
            <button
              onClick={() => toggleTheme("dark")}
              className={`theme-toggle-button ${
                theme === "dark" ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faMoon} className="icon" />
            </button>
          </div>

          {/* Menu List */}
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/" onClick={toggleMenu} className="menu-item-link">
                即時比價
              </Link>
              <span className="menu-item-icon">›</span>
            </li>
            <li className="menu-item">
              <Link to="/news" onClick={toggleMenu} className="menu-item-link">
                資訊分享
              </Link>
              <span className="menu-item-icon">›</span>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navigation;
