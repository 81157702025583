import { useEffect, useState } from "react";
import "./NewsHome.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faSearch,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocation } from "react-router-dom";

/**
 * @typedef {Object} Article
 * @property {string} URL
 * @property {string} Cover
 * @property {string} Title
 * @property {string} Excerpt
 * @property {string} PublishedAt
 * @property {number} Views
 */

const NewsHome = () => {
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
  const { theme } = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tagId = queryParams.get("tag"); // 獲取 tag 參數
  const subCategoryId = queryParams.get("sub_category"); // 獲取 sub_category 參數

  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState(tagId); // 新增狀態來追蹤當前選中的標籤
  const [categories, setCategories] = useState([]);
  const [openCategories, setOpenCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [_sharedData, setSharedData] = useState({
    tags: [],
    categories: [],
    popularArticles: [],
  });

  useEffect(() => {
    const root = document.documentElement;
    if (theme === "light") {
      root.style.setProperty("--theme-bg", "url(/assets/light.svg)");
      root.style.setProperty("--news-home-color", "#1E293B");
      root.style.setProperty("--news-home-title-color", "#1E293B");
      root.style.setProperty("--news-home-content-bg", "#FFFFFF");
      root.style.setProperty("--news-home-content-border", "none");
      root.style.setProperty(
        "--news-home-sidebar-border",
        "rgba(71, 85, 105, 0.2)"
      );
      root.style.setProperty(
        "--news-home-label-button-border",
        "rgba(71, 85, 105, 0.6)"
      );
      root.style.setProperty("--news-home-label-button-color", "#334155");
      root.style.setProperty(
        "--news-home-label-button-active-bg",
        "rgba(212, 161, 0, 0.2)"
      );
      root.style.setProperty("--news-home-article-title-overlay-bg", "#000000");
      root.style.setProperty(
        "--news-home-article-title-overlay-color",
        "#FFFFFF"
      );
      root.style.setProperty(
        "--news-home-article-date-overlay-bg",
        "rgba(0, 0, 0, 0.5)"
      );
      root.style.setProperty(
        "--news-home-article-date-overlay-color",
        "#FFFFFF"
      );
      root.style.setProperty("--news-home-article-title-color", "#334155");
      root.style.setProperty("--news-home-article-excerpt-color", "#94A3B8");
      root.style.setProperty("--search-bar-border", "#334155");
      root.style.setProperty("--search-input-color", "#94A3B8");
      root.style.setProperty("--search-icon-color", "#334155");
      root.style.setProperty("--category-title-color", "#12161C");
      root.style.setProperty("--category-button-color", "#12161C");
      root.style.setProperty(
        "--category-content-color",
        "rgba(51, 65, 85, 0.5)"
      );
      root.style.setProperty(
        "--news-home-mobile-hot-article-divider-color",
        "rgba(71, 85, 105, 0.3)"
      );
      root.style.setProperty("--news-home-article-date-color", "#334155"); // TODO:
      root.style.setProperty("--news-home-article-title-color", "#334155");
      root.style.setProperty("--news-home-article-excerpt-color", "#94A3B8");
    } else {
      root.style.setProperty("--theme-bg", "url(/assets/dark.svg)");
      root.style.setProperty("--news-home-color", "#FFFFFF");
      root.style.setProperty(
        "--news-home-title-color",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty("--news-home-content-bg", "rgba(24, 26, 31, 1)");
      root.style.setProperty(
        "--news-home-content-border",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty(
        "--news-home-sidebar-border",
        "rgba(125, 162, 212, 0.2)"
      );
      root.style.setProperty(
        "--news-home-label-button-border",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty("--news-home-label-button-color", "#FFFFFF");
      root.style.setProperty(
        "--news-home-label-button-active-bg",
        "rgba(214, 198, 171, 0.2)"
      );
      root.style.setProperty(
        "--news-home-article-title-overlay-bg",
        "rgba(0, 0, 0, 0.5)"
      );
      root.style.setProperty(
        "--news-home-article-title-overlay-color",
        "#FFFFFF"
      );
      root.style.setProperty(
        "--news-home-article-date-overlay-bg",
        "rgba(0, 0, 0, 0.5)"
      );
      root.style.setProperty(
        "--news-home-article-date-overlay-color",
        "#FFFFFF"
      );
      root.style.setProperty(
        "--news-home-article-title-color",
        "rgba(51, 65, 85, 1)"
      );
      root.style.setProperty(
        "--news-home-article-excerpt-color",
        "rgba(148, 163, 184, 1)"
      );
      root.style.setProperty("--search-bar-border", "rgba(148, 163, 184, 1)");
      root.style.setProperty("--search-input-color", "#FFFFFF");
      root.style.setProperty("--search-icon-color", "#FFFFFF");
      root.style.setProperty(
        "--category-title-color",
        "rgba(214, 198, 171, 1)"
      );
      root.style.setProperty("--category-button-color", "#FFFFFF");
      root.style.setProperty(
        "--category-content-color",
        "rgba(148, 163, 184, 1)"
      );
      root.style.setProperty(
        "--news-home-mobile-hot-article-divider-color",
        "rgba(214, 198, 171, 0.3)"
      );
      root.style.setProperty(
        "--news-home-article-date-color",
        "rgba(255, 255, 255, 0.4)"
      );
      root.style.setProperty("--news-home-article-title-color", "#FFFFFF");
      root.style.setProperty(
        "--news-home-article-excerpt-color",
        "rgba(148, 163, 184, 0.7)"
      );
    }
  }, [theme]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        console.log("fetching articles");
        let url = `${BACKEND_HOST}/news`;

        // 根據參數構建 URL
        if (tagId) {
          url += `?tag=${tagId}`;
        } else if (subCategoryId) {
          url += `?sub_category=${subCategoryId}`;
        }

        const response = await fetch(url);
        const data = await response.json();
        const formattedArticles = data.map((article) => ({
          URL: article.URL,
          Views: article.views,
          Cover: article.cover,
          Title: article.title,
          Excerpt: article.excerpt,
          PublishedAt: article.published_at,
        }));
        setArticles(formattedArticles);
        console.log("articles fetched:", formattedArticles);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [BACKEND_HOST, tagId, subCategoryId]);

  // 獲取新聞標籤列表
  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await axios.get(`${BACKEND_HOST}/news/tags`);
        setTags(response.data);
      } catch (error) {
        console.error("無法載入標籤:", error);
      }
    };

    fetchKeywords();
  }, [BACKEND_HOST]);

  // 獲取新聞分類列表
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BACKEND_HOST}/news/categories`);
        // 初始化時將所有分類設為關閉狀態
        const initialOpenState = {};
        response.data.forEach((category) => {
          initialOpenState[category.id] = true;
        });
        console.log("初始化 initialOpenState:", initialOpenState);
        setOpenCategories(initialOpenState);
        setCategories(response.data);
      } catch (error) {
        console.error("無法載入分類:", error);
      }
    };

    fetchCategories();
  }, [BACKEND_HOST]);

  // 獲取共用資料
  useEffect(() => {
    const fetchSharedData = async () => {
      try {
        const [tagsRes, categoriesRes, popularRes] = await Promise.all([
          axios.get(`${BACKEND_HOST}/news/tags`),
          axios.get(`${BACKEND_HOST}/news/categories`),
          axios.get(`${BACKEND_HOST}/news?sort_by=views&limit=3`),
        ]);

        setSharedData({
          tags: tagsRes.data,
          categories: categoriesRes.data,
          popularArticles: popularRes.data,
        });

        // 將資料存入 localStorage，設定過期時間
        localStorage.setItem(
          "newsSharedData",
          JSON.stringify({
            data: {
              tags: tagsRes.data,
              categories: categoriesRes.data,
              popularArticles: popularRes.data,
            },
            timestamp: Date.now(),
          })
        );
      } catch (error) {
        console.error("獲取共用資料失敗:", error);

        // 如果請求失敗，嘗試從 localStorage 讀取
        const cached = localStorage.getItem("newsSharedData");
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          // 檢查是否過期（例如 1 小時）
          if (Date.now() - timestamp < 3600000) {
            setSharedData(data);
          }
        }
      }
    };

    fetchSharedData();
  }, [BACKEND_HOST]);

  const handleSearch = async (event) => {
    if (event.key === "Enter") {
      try {
        const url = `${BACKEND_HOST}/news?keyword=${searchTerm}`;
        const response = await fetch(url);
        const data = await response.json();
        const formattedArticles = data.map((article) => ({
          URL: article.URL,
          Cover: article.cover,
          Title: article.title,
          Excerpt: article.excerpt,
          PublishedAt: article.published_at,
        }));
        setArticles(formattedArticles);
        console.log("filteredArticles:", formattedArticles);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    }
  };

  const toggleCategory = (categoryId) => {
    setOpenCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  return (
    <>
      <Helmet>
        <title>USDT比價網 | 加密貨幣資訊分享</title>
        <meta
          name="description"
          content="即時掌握區塊鏈與加密貨幣交易所的最新動態與深度分析，為投資者提供最前沿的市場新聞"
        />
        <meta property="og:url" content="https://www.usdtprice.com/news" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="USDT比價網 | 加密貨幣資訊分享" />
        <meta
          property="og:description"
          content="即時掌握區塊鏈與加密貨幣交易所的最新動態與深度分析，為投資者提供最前沿的市場新聞"
        />
        <meta
          property="og:image"
          content="https://cryptologos.cc/logos/tether-usdt-logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="usdtprice.com" />
        <meta property="twitter:url" content="https://www.usdtprice.com/news" />
        <meta name="twitter:title" content="USDT比價網 | 加密貨幣資訊分享" />
        <meta
          property="twitter:description"
          content="即時掌握區塊鏈與加密貨幣交易所的最新動態與深度分析，為投資者提供最前沿的市場新聞"
        />
        <meta
          name="twitter:image"
          content="https://cryptologos.cc/logos/tether-usdt-logo.png"
        />
      </Helmet>

      <div className="news-home-app-wrapper">
        <div className="news-home">
          <div className="news-home__container">
            <h1 className="news-home__title">加密貨幣資訊分享</h1>
            <div className="news-home__label-container">
              <div className="news-home__labels">
                {tags.length > 0 && (
                  <>
                    <button
                      className={`news-home__label-button ${
                        activeTag === null ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveTag(null);
                        window.location.href = `/news`;
                      }}
                    >
                      全部
                    </button>
                    {tags.map((tag, index) => (
                      <button
                        key={index}
                        className={`news-home__label-button ${
                          activeTag === tag.tag_id ? "active" : ""
                        }`}
                        onClick={() => {
                          setActiveTag(tag.tag_id);
                          window.location.href = `/news?tag=${tag.tag_id}`;
                        }}
                      >
                        {tag.name}
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="news-home_content">
              <div className="news-home__article-grid pc-only">
                {isLoading ? null : articles.length === 0 ? (
                  <div className="news-home__no-articles">
                    該搜尋沒有匹配文章
                  </div>
                ) : (
                  articles.map((article, index) => (
                    <a
                      key={index}
                      href={article.URL}
                      className={`news-home__article-card`}
                    >
                      <div
                        className={`news-home__article-images
                          ${index === 0 ? "first" : "not-first"}
                        `}
                      >
                        <img
                          src={article.Cover}
                          alt={article.Title}
                          className={`news-home__article-main-image
                            ${index === 0 ? "first" : "not-first"}
                        `}
                        />
                        {index === 0 && (
                          <h2 className="news-home__article-title-overlay">
                            {article.Title}
                          </h2>
                        )}
                        {index !== 0 && (
                          <div className="news-home__article-date-overlay">
                            <FontAwesomeIcon
                              icon={faClockRotateLeft}
                              className="icon-clock"
                            />{" "}
                            {article.PublishedAt}
                          </div>
                        )}
                        {index !== 0 && (
                          <div className="news-home__article-views-overlay">
                            {article.Views} views
                          </div>
                        )}
                      </div>
                      {index !== 0 && (
                        <div className="news-home__article-content">
                          <h2 className="news-home__article-title">
                            {article.Title}
                          </h2>
                          <p className="news-home__article-excerpt">
                            {article.Excerpt}
                          </p>
                        </div>
                      )}
                    </a>
                  ))
                )}
              </div>
              <div className="news-home__sidebar pc-only">
                <div className="search-bar">
                  <FontAwesomeIcon icon={faSearch} className="search-icon" />

                  <input
                    type="text"
                    placeholder="搜尋幣圈最新資訊"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleSearch}
                    className="search-input"
                  />
                </div>

                <div
                  className="article-categories"
                  style={{ marginTop: "40px" }}
                >
                  <h2 className="news-home-category-title">文章分類</h2>
                  {categories.map((category) => (
                    <div key={category.id} className="news-home-category-item">
                      <button
                        className="news-home-category-button"
                        onClick={() => toggleCategory(category.id)}
                      >
                        {category.name}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={
                            openCategories[category.id] ? "rotated" : ""
                          }
                        />
                      </button>
                      {openCategories[category.id] &&
                        category.sub_categories && (
                          <ul className="news-home-category-content">
                            {category.sub_categories.map((sub) => (
                              <li
                                key={sub.id}
                                onClick={() => {
                                  setActiveTag(sub.tag_id);
                                  window.location.href = `/news?sub_category=${sub.id}`;
                                }}
                              >
                                {sub.name} ({sub.article_count})
                              </li>
                            ))}
                          </ul>
                        )}
                    </div>
                  ))}
                </div>
                <hr className="hot-article-divider" />
                <div className="news-home__hot-article-container">
                  <h2 className="hot-article-title">熱門文章</h2>
                </div>

                {articles.map((article, index) => (
                  <a
                    key={index}
                    href={article.URL}
                    className="news-home__sidebar-article-card"
                  >
                    <div className="news-home__sidebar-article-images">
                      <img
                        src={article.Cover}
                        alt={article.Title}
                        className="news-home__sidebar-article-main-image"
                      />
                      <div className="news-home__sidebar-article-mask"></div>
                      <h2 className="news-home__sidebar-article-title-overlay">
                        {article.Title}
                      </h2>
                    </div>
                  </a>
                ))}
              </div>
              <div className="news-home__article-grid mobile-only">
                {articles.slice(0, 1).map((article, index) => (
                  <a
                    key={index}
                    href={article.URL}
                    className="news-home__article-card"
                  >
                    <div className="news-home__article-images">
                      <img
                        src={article.Cover}
                        alt={article.Title}
                        className="news-home__article-main-image"
                      />
                      <h2 className="news-home__article-title-overlay">
                        {article.Title}
                      </h2>
                    </div>
                  </a>
                ))}
              </div>
              <div className="news-home__sidebar mobile-only">
                <div className="news-home__hot-article-container">
                  <h2 className="hot-article-title">熱門文章</h2>
                </div>

                {articles.map((article, index) => (
                  <a
                    key={index}
                    href={article.URL}
                    className="news-home__sidebar-article-card"
                  >
                    <div className="news-home__sidebar-article-images">
                      <img
                        src={article.Cover}
                        alt={article.Title}
                        className="news-home__sidebar-article-main-image"
                      />
                      <div className="news-home__sidebar-article-mask"></div>
                      <h2 className="news-home__sidebar-article-title-overlay">
                        {article.Title}
                      </h2>
                    </div>
                  </a>
                ))}
              </div>
              <hr className="hot-article-divider2" />

              <div className="news-home__article-grid mobile-only">
                {articles.slice(1).map((article, index) => (
                  <a
                    key={index}
                    href={article.URL}
                    className="news-home__other-article-card"
                  >
                    <div className="news-home__article-images">
                      <img
                        src={article.Cover}
                        alt={article.Title}
                        className="news-home__article-main-image"
                      />
                    </div>
                    <div className="news-home__article-text-content">
                      <h2 className="news-home__article-title">
                        {article.Title}
                      </h2>
                      <p className="news-home__article-excerpt">
                        {article.Excerpt}
                      </p>
                      <div className="news-home__article-last-line">
                        <div className="news-home__article-date">
                          <FontAwesomeIcon
                            icon={faClockRotateLeft}
                            className="icon-clock"
                          />
                          {article.PublishedAt}
                        </div>
                        <div className="news-home__article-views">
                          {article.Views} views
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsHome;
