// admin/tags.js
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./tags.css";

const NewsTags = () => {
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [editTagIndex, setEditTagIndex] = useState(-1);
  const [editTagName, setEditTagName] = useState("");
  const [initialTags, setInitialTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      const response = await fetch(`${BACKEND_HOST}/news/tags`);
      if (response.ok) {
        const data = await response.json();
        setTags(data);
        setInitialTags(data);
      } else {
        console.error("獲取標籤失敗");
      }
    };

    fetchTags();
  }, [BACKEND_HOST]);

  const addTag = async () => {
    if (newTag) {
      let newTagData = {
        name: newTag,
        order: tags.length + 1,
        tag_id: Math.random().toString(36).substring(2, 12),
      };
      console.log("newTagData:", newTagData);
      // 發送 POST 請求到後端 API
      const response = await fetch(`${BACKEND_HOST}/admin/tag`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bestUsdtPrice",
        },
        body: JSON.stringify(newTagData),
      });

      if (response.ok) {
        setTags([...tags, newTagData]);
        setNewTag("");
      } else {
        // 處理錯誤情況
        console.error("新增標籤失敗");
      }
    }
  };

  const editTag = (index) => {
    setEditTagIndex(index);
    setEditTagName(tags[index].name);
  };

  const saveTag = async () => {
    const updatedTags = [...tags];
    updatedTags[editTagIndex].name = editTagName;
    setTags(updatedTags);
    setEditTagIndex(-1);
    setEditTagName("");

    const response = await fetch(
      `${BACKEND_HOST}/admin/tags/${tags[editTagIndex].tag_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bestUsdtPrice",
        },
        body: JSON.stringify({ name: editTagName }),
      }
    );

    console.log("saveTag:", response.ok);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return; // 如果沒有目的地（例如拖出範圍），則不處理

    const reorderedTags = Array.from(tags);
    const [movedTag] = reorderedTags.splice(source.index, 1);
    reorderedTags.splice(destination.index, 0, movedTag);

    setTags(reorderedTags);

    // 這裡可以調用後端 API 更新順序
    // await updateTagOrder(reorderedTags);
  };

  const deleteTag = async (index) => {
    const tagToDelete = tags[index];
    try {
      const response = await fetch(
        `${BACKEND_HOST}/admin/tag/${tagToDelete.tag_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bestUsdtPrice",
          },
        }
      );
      if (response.ok) {
        const updatedTags = tags.filter((_, i) => i !== index);
        setTags(updatedTags);
      } else {
        console.error("刪除標籤失敗");
      }
    } catch (error) {
      console.error("刪除標籤出錯", error);
    }
  };

  const saveAllTags = async () => {
    const updatedTags = tags.map((tag, index) => ({
      ...tag,
      order: index,
    }));
    setTags(updatedTags);

    const response = await fetch(`${BACKEND_HOST}/admin/tags`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bestUsdtPrice",
      },
      body: JSON.stringify(updatedTags),
    });
    console.log("saveAllTags:", response.ok);
  };

  const isOrderChanged = JSON.stringify(tags) !== JSON.stringify(initialTags);

  return (
    <div className="tags-container">
      <h1>標籤管理</h1>
      <div>
        <input
          type="text"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder="輸入標籤名稱"
        />
        <button onClick={addTag}>新增標籤</button>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="tags" type="group">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="tags-list"
            >
              {tags.map((tag, index) => (
                <Draggable
                  key={tag.tag_id}
                  draggableId={tag.tag_id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="tag-item"
                    >
                      <span
                        {...provided.dragHandleProps}
                        className="drag-handle"
                      >
                        ☰
                      </span>
                      {editTagIndex === index ? (
                        <>
                          <input
                            type="text"
                            value={editTagName}
                            onChange={(e) => setEditTagName(e.target.value)}
                          />
                          <button onClick={saveTag}>儲存</button>
                        </>
                      ) : (
                        <>
                          <span>{tag.name}</span>
                          <button onClick={() => editTag(index)}>
                            編輯名字
                          </button>
                          <button onClick={() => deleteTag(index)}>刪除</button>
                        </>
                      )}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <button onClick={saveAllTags} disabled={!isOrderChanged}>
        儲存標籤順序
      </button>
    </div>
  );
};

export default NewsTags;
